<template>
  <b-container :class="mainClass" :style="mainStyle" fluid="sm">
    <div class="text-center my-0 py-0">
      <b-icon icon="file-earmark-person" font-scale="10" variant="secondary" />
      <h1 class="text-center mt-1 mb-3">Mot de passe perdu</h1>
    </div>

    <b-form @submit="onSubmit">
      <FormRowFullCol>
        <b-input-group size="lg">
          <InputPrependIcon icon="person-fill" />
          <b-form-input
            type="text"
            placeholder="Utilisateur"
            v-model="form.username_reg"
            :state="onStateUsername(form.username_reg)"
            @focus="onFocusUsername()"
            @blur="onBlurUsername(form.username_reg)"
            @input="onInputUsername()"
            :formatter="lowerCase"
            maxlength="30"
            required
          />

          <FormInvalidFeedback
            class="bounce_effect"
            label="Nom d'utilisateur indisponible"
            :state="onStateUsername(form.username_reg)"
          />
        </b-input-group>
      </FormRowFullCol>

      <FormRowFullCol>
        <b-input-group size="lg">
          <InputPrependIcon icon="mailbox" />
          <b-form-input
            type="email"
            placeholder="Courriel"
            v-model="form.email1"
            :state="onStateCourriel()"
            :formatter="lowerCase"
            maxlength="50"
            required
          />
          <FormInvalidFeedback
            class="bounce_effect"
            label="L'adresse de courriel est invalide"
            :state="onStateCourriel()"
          />
        </b-input-group>
      </FormRowFullCol>

      <FormRowFullCol>
        <b-button
          variant="info"
          class="text-center slide_in_from_right"
          size="lg"
          type="submit"
          large
          block
          :disabled="!isPassedCheck"
          >Retrouver le mot de passe</b-button
        >
        <!--  -->
      </FormRowFullCol>
    </b-form>

    <hr class="bg-info hr-50 " />

    <!-- Return to login -->
    <FormRowFullCol>
      <b-link tag="a" to="login" class="text-info text-right slide_in_from_left"
        >Vous avez déja un compte ?</b-link
      >
    </FormRowFullCol>
  </b-container>
</template>

<script>
import forms from '../mixins/forms'

export default {
  components: {
    FormRowFullCol: () => import('../components/FormRowFullCol'),
    FormRowDualCol: () => import('../components/FormRowDualCol'),
    FormRowHalfCol: () => import('../components/FormRowHalfCol'),
    InputPrependIcon: () => import('../components/InputPrependIcon'),
    FormTermsAndUse: () => import('../components/FormTermsAndUse'),
    FormInvalidFeedback: () => import('../components/FormInvalidFeedback'),
  },
  mixins: [forms],
  data() {
    return {
      mainClass: ['mt-5'],
      mainStyle: ['max-width: 400px'],
      isPasswordVisible: false,
      form: {
        username_reg: '',
        email1: '',
        captcha: '',
      },
    }
  },
  computed: {
    isPassedCheck() {
      if (!this.isPassed.username) return false
      else if (!this.isPassed.email) return false
      else return true
    },
  },
  mounted() {
    // this.$store.dispatch('store_csrf/get')
    this.$store.dispatch('store_csrf/test')
  },
  beforeDestroy() {
    this.onDestroy()
  },
  methods: {
    showPassword() {
      this.isPasswordVisible = !this.isPasswordVisible
    },
    onSubmit(event) {
      event.preventDefault()

      event.preventDefault()
      this.$store.dispatch('store_check_form/overlayState', true)

      this.$root.timeout = setTimeout(() => {
        this.$store.dispatch('store_check_form/overlayState', false)
        this.$store.dispatch('store_auth/password_recovery', this.form)
        this.$store.dispatch('store_debug/set', this.form)
      }, 2000)
    },
    onDestroy() {
      clearTimeout(this.$root.timeout)
      this.$store.dispatch('store_csrf/delete')
      this.form = null
      this.$store.dispatch('store_debug/set')
    },
  },
}
</script>
